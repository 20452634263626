import React from 'react';
import {useSearchParams} from 'react-router-dom';

import {Button} from './button';
import github_logo from '../assets/github.svg';
import google_logo from '../assets/google.svg';
import {useTrackSubmit} from '../contexts/analytics';

enum SignInProvider {
  google = 'google',
  github = 'github',
  sso = 'sso',
}

interface SignInButtonProps {
  provider: SignInProvider;
  link?: string;
}

export const providerNameMap = {
  github: 'GitHub',
  google: 'Google',
  sso: 'SSO',
};

const providerLogoMap = {
  github: github_logo,
  google: google_logo,
  sso: undefined,
};

function SignInButton({provider, link}: SignInButtonProps) {
  const trackSubmit = useTrackSubmit();

  const [searchParams] = useSearchParams();
  const next = searchParams.get('next');
  const publicId = searchParams.get('organization_public_id');
  const iframe_origin = searchParams.get('iframe_origin');
  const formRef = React.useRef<HTMLFormElement>(null);

  React.useEffect(() => {
    if (formRef.current) {
      trackSubmit(formRef.current, 'signIn', {provider});
    }
  }, [trackSubmit, provider]);

  return (
    <form
      method="GET"
      action={link ? link : `/auth/${provider}/login`}
      ref={formRef}
      data-testid={`${provider}-sign-form`}
    >
      {next && <input type="hidden" name="next" value={next} />}
      {publicId && <input type="hidden" name="organization_public_id" value={publicId} />}
      {iframe_origin && <input type="hidden" name="iframe_origin" value={iframe_origin} />}
      <Button type="submit" data-testid={provider}>
        {providerLogoMap[provider] ? <img src={providerLogoMap[provider]} /> : null}
        Continue with {providerNameMap[provider]}
      </Button>
    </form>
  );
}

export {SignInButton, SignInProvider};
