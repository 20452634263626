import React from 'react';
import styled from 'styled-components';

import logo from '../assets/Logo.png';

function Logo() {
  return (
    <DagsterLogo href="https://www.dagster.io/cloud">
      <img src={logo} alt="logo" />
    </DagsterLogo>
  );
}

const DagsterLogo = styled.a`
  img {
    width: 180px;
    height: 38px;
  }
  margin: 42px auto 68px auto;
`;

export {Logo};
