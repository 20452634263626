const SEON_JS_API_URL = 'https://cdn.seondf.com/js/v5/agent.js';

let seon: any = null;

const queue: Array<[(seon: any) => void, () => void]> = [];
const script = document.createElement('script');

document.body.appendChild(script);
script.onload = () => {
  seon = (window as any).seon;
  while (queue.length) {
    queue.pop()![0](seon);
  }
};
let didError = false;
script.onerror = () => {
  didError = true;
  while (queue.length) {
    queue.pop()![1]();
  }
};
script.src = SEON_JS_API_URL;

export function withSeon(): Promise<any> {
  return new Promise((res, rej) => {
    if (didError) {
      rej();
    } else if (seon) {
      res(seon);
    } else {
      queue.push([res, rej]);
    }
  });
}
