import {useEffect} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

let queue: Array<[(token: string) => void, string]> = [];
export const useRecaptcha = () => {
  const {executeRecaptcha} = useGoogleReCaptcha();
  useEffect(() => {
    if (executeRecaptcha) {
      queue.map(async ([cb, action]) => {
        const result = await executeRecaptcha(action);
        cb(result);
      });
      queue = [];
    }
  }, [executeRecaptcha]);
  return {
    async execute(action: string): Promise<string> {
      return new Promise(async (res) => {
        if (!executeRecaptcha) {
          queue.push([res, action]);
        } else {
          const result = await executeRecaptcha(action);
          res(result);
        }
      });
    },
  };
};
