import {useSearchParams} from 'react-router-dom';

// Keep in sync with backend
export const PARTNER_REFERRAL_COOKIE = 'dagster-plus-partner-referral';
// Didn't want to use referrer since its a common query string param so add a "d" for dagster.
export const PARTNER_REFERRAL_PARAM_KEY = 'dreferrer';

export const useSetReferrerCookie = () => {
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get(PARTNER_REFERRAL_PARAM_KEY);
  if (referrer) {
    document.cookie = `${PARTNER_REFERRAL_COOKIE}=${referrer};path=/;`;
    searchParams.delete(PARTNER_REFERRAL_PARAM_KEY);
    window.history.replaceState(null, '', `${location.pathname}?${searchParams}`);
  }
};

export function SetReferrerCookie() {
  useSetReferrerCookie();
  return null;
}
