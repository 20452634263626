import {Colors} from '@dagster-io/ui-components';
import React from 'react';

export const SigninCallout = () => {
  return (
    <a
      href="/"
      style={{
        color: Colors.ForestGreen,
        fontSize: '18px',
        position: 'absolute',
        top: '42px',
        right: '42px',
      }}
    >
      Sign in
    </a>
  );
};
