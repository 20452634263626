import React, {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import styled from 'styled-components';

import {Card} from '../components/card';
import {useTrackEvent} from '../contexts/analytics';

export function SSOLogin() {
  const trackEvent = useTrackEvent();

  const [organization, setOrganization] = useState(``);

  const [searchParams] = useSearchParams();
  const next = searchParams.get('next');

  const confirm = () => {
    trackEvent('orgNameEnter', {organization});

    // TODO: push to react router history and avoid the server round trip
    const url = new URL(`/auth/${organization}`, window.location.origin);
    if (next) {
      url.search = new URLSearchParams({next}).toString();
    }
    window.location.href = url.toString();
  };

  return (
    <div style={{textAlign: 'left'}}>
      <Card flex={{direction: 'column', gap: 6}}>
        <Prompt>Organization name</Prompt>
        <SubDomainForm>
          <SubDomainInputContainer>
            <SubDomainInput
              data-cy="subdomain_input"
              placeholder="subdomain"
              type="text"
              value={organization}
              onChange={(event) => setOrganization(event.target.value)}
              onKeyDown={(event) => event.key === 'Enter' && confirm()}
            />
            <SubDomainSuffix>.dagster.plus</SubDomainSuffix>
          </SubDomainInputContainer>
          <SubDomainInputButton onClick={confirm}>
            <SubDomainInputButtonText>Continue</SubDomainInputButtonText>
          </SubDomainInputButton>
        </SubDomainForm>
      </Card>
      <Prompt style={{marginTop: '12px'}}>
        Not yet on Dagster+? <a href="https://dagster.plus/signup">Sign up</a>.
      </Prompt>
    </div>
  );
}

const Prompt = styled.div`
  margin-bottom: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-weight: 400;
`;

const SubDomainForm = styled.div`
  width: 100%;
`;

const SubDomainInputContainer = styled.div`
  border: none;
  flex-grow: 1;
  line-height: 20px;
  margin: 0 0 10px 0;
  position: relative;
`;
const SubDomainInput = styled.input`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-weight: 400;
  border: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  line-height: 20px;
  padding: 0;
  margin: 0;
  text-align: right;
  padding: 6px 50% 6px 12px;
  background-color: transparent;
  border-radius: 8px;
  box-shadow:
    rgba(189, 186, 183, 1) inset 0px 0px 0px 1px,
    rgba(233, 232, 232, 1) inset 2px 2px 1.5px;
  transition: box-shadow 150ms;
  &:focus {
    box-shadow:
      rgba(189, 186, 183, 1) inset 0px 0px 0px 1px,
      rgba(233, 232, 232, 1) inset 2px 2px 1.5px,
      rgba(58, 151, 212, 0.6) 0 0 0 3px;
    outline: none !important;
  }
`;
const SubDomainInputButton = styled.div`
  background-color: rgba(35, 31, 27, 1);
  box-shadow: rgb(35 31 27) inset 0px 0px 0px 1px;
  width: 100%;
  position: relative;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.00017em;
  cursor: pointer;
  user-select: none;
  transition: box-shadow 150ms;
`;
const SubDomainInputButtonText = styled.div`
  position: static;
  height: 20px;
  top: 6px;
  margin: 8px 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  color: white;
`;
const SubDomainSuffix = styled.div`
  padding: 6px 0px 6px 0px;
  line-height: 20px;
  margin-left: 50%;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-weight: 400;
`;
