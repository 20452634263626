import {AnalyticsBrowser} from '@segment/analytics-next';
import * as React from 'react';

import {usePageViewContext} from './pageView';

const AnalyticsContext = React.createContext<AnalyticsBrowser | null>(undefined!);

interface Props {
  children: React.ReactNode;
  segmentWriteKey: string;
  segmentCDNProxy: string;
}

export const AnalyticsProvider = ({children, segmentWriteKey, segmentCDNProxy}: Props) => {
  const analytics = React.useMemo(
    () =>
      segmentWriteKey
        ? AnalyticsBrowser.load({
            writeKey: segmentWriteKey,
            cdnURL: segmentCDNProxy === '' ? undefined : segmentCDNProxy,
          })
        : null,
    [segmentWriteKey, segmentCDNProxy],
  );

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

// To expose more Analytics.js functionality, export a new hook from this file.
export const useAnalytics = () => {
  const result = React.useContext(AnalyticsContext);
  if (result === undefined) {
    throw new Error('Analytics context used outside of its provider.');
  }
  return result;
};

export const useTrackEvent = () => {
  const analytics = useAnalytics();
  const pathValues = usePageViewContext();

  return React.useCallback(
    (eventName: string, properties?: Record<string, any>) => {
      analytics?.track(eventName, {...properties, ...pathValues});
    },
    [analytics, pathValues],
  );
};

export const useTrackSubmit = () => {
  const analytics = useAnalytics();
  const pathValues = usePageViewContext();

  return React.useCallback(
    (form: HTMLFormElement, eventName: string, properties?: Record<string, any>) => {
      analytics?.trackSubmit(form, eventName, {...properties, ...pathValues});
    },
    [analytics, pathValues],
  );
};
