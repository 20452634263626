import {Box} from '@dagster-io/ui-components';
import React from 'react';

import {OrganizationLoginButton} from '../components/OrganizationLoginButton';
import {Card} from '../components/card';
import {LOGIN_SELECT_ORGANIZATION_PAGE_DATA, usePageData} from '../hooks/usePageData';

export function SelectOrganization() {
  const params = React.useMemo(() => new URLSearchParams(window.location.search), []);
  const unsortedOrganizations = usePageData<LOGIN_SELECT_ORGANIZATION_PAGE_DATA>().organizations;
  const organizations = React.useMemo(
    () => unsortedOrganizations.slice().sort((a, b) => (a.name > b.name ? 1 : -1)),
    [unsortedOrganizations],
  );
  return (
    <Card title="Select an organization">
      <Box flex={{direction: 'column', gap: 6}}>
        {organizations.map((org) => (
          <OrganizationLoginButton key={org.id} org={org} next={params.get('next')} />
        ))}
      </Box>
    </Card>
  );
}
