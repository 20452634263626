import {Box} from '@dagster-io/ui-components';
import React from 'react';
import styled from 'styled-components';

import {Title} from './typography';

type Props = {
  title?: React.ReactNode;
  children: React.ReactNode;
  $background?: string;
  $borderColor?: string;
} & Parameters<typeof CardElement>[0];

export const Card = ({title, children, ...rest}: Props) => {
  return (
    <CardElement {...rest}>
      {title ? <Title>{title}</Title> : null}
      {children}
    </CardElement>
  );
};

const CardElement = styled(Box)`
  background: white;
  border-radius: 12px;
  padding: 36px;
`;
