import React from 'react';
import {useSearchParams} from 'react-router-dom';

import {Button} from './button';
import github_logo from '../assets/github.svg';
import google_logo from '../assets/google.svg';
import {useRecaptcha} from '../hooks/useRecaptcha';

enum SignUpProvider {
  google = 'google',
  github = 'github',
}

interface SignUpButtonProps {
  provider: SignUpProvider;
  setSubmitting: () => void;
}

const providerNameMap = {
  github: 'GitHub',
  google: 'Google',
};

const providerLogoMap = {
  github: github_logo,
  google: google_logo,
};

const SignUpButton = (props: SignUpButtonProps) => {
  const [searchParams] = useSearchParams();
  const next = searchParams.get('next');
  const token = searchParams.get('token') || '';
  const [recaptchaToken, setRecaptchaToken] = React.useState<string | null>(null);
  const {execute} = useRecaptcha();
  const formRef = React.useRef<HTMLFormElement>(null);

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    if (recaptchaToken) {
      return;
    }
    props.setSubmitting();
    e.preventDefault();
    const token = await execute('signup');
    setRecaptchaToken(token);
  }

  React.useEffect(() => {
    if (recaptchaToken) {
      requestAnimationFrame(() => {
        formRef.current?.submit();
      });
    }
  }, [recaptchaToken]);

  return (
    <form method="GET" action={`/signup/${props.provider}`} onSubmit={onSubmit} ref={formRef}>
      <input type="hidden" name="token" value={token} />
      <input type="hidden" name="recaptcha_token" value={recaptchaToken || ''} />
      {next && <input type="hidden" name="next" value={next} />}
      <Button type="submit" id={`submit-${props.provider}`}>
        <img src={providerLogoMap[props.provider]} />
        Sign up with {providerNameMap[props.provider]}
      </Button>
    </form>
  );
};

export {SignUpButton, SignUpProvider};
