import {extractCloudPortalSettings} from '../extractCloudPortalSettings';

export type OrgLoginData = {
  id: string;
  name: string;
  // keep in track with OrganizationStatus enum
  status: 'ACTIVE' | 'READ_ONLY' | 'PENDING_DELETION' | 'SUSPENDED';
};
export type SIGNUP_ORGANIZATION_PAGE_DATA = {
  organizations: OrgLoginData[];
  name: string;
  email: string;
};

export type LOGIN_SELECT_ORGANIZATION_PAGE_DATA = {
  organizations: OrgLoginData[];
};

export type USER_PROFILE_PAGE_DATA = {
  profile: {
    firstName?: string;
    lastName?: string;
    title?: string;
    experience?: string;
    email?: string;
  };
};

export type PAGE_DATA =
  | SIGNUP_ORGANIZATION_PAGE_DATA
  | LOGIN_SELECT_ORGANIZATION_PAGE_DATA
  | USER_PROFILE_PAGE_DATA;

export function usePageData<T extends PAGE_DATA>(): T {
  return extractCloudPortalSettings().pageData as T;
}
