import * as React from 'react';

type PageViewContextValue = {path: string; specificPath: string};

export const PageViewContext = React.createContext<PageViewContextValue>(undefined!);

export const usePageViewContext = () => {
  const result = React.useContext(PageViewContext);
  if (!result) {
    throw new Error(
      'Page view context used outside of its provider. You must use a <Page> component.',
    );
  }
  return result;
};
