import {Box, Colors} from '@dagster-io/ui-components';
import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {Card} from '../components/card';
import {EmailSignIn} from '../components/emailSignIn';
import {SignInButton, SignInProvider} from '../components/signinButton';
import {TextSurroundedByLines} from '../components/textSurroundedByLines';

function Login() {
  return (
    <Box flex={{direction: 'column', gap: 6}}>
      <SignupCalloutWrapper>
        <div>Don&apos;t have an account?</div>
        <LinkWrapper>
          <Link to="/signup">Get started for free</Link>
        </LinkWrapper>
      </SignupCalloutWrapper>
      <Card title="Sign in to Dagster+" flex={{direction: 'column', gap: 6}}>
        <SignInButton provider={SignInProvider.google} />
        <SignInButton provider={SignInProvider.github} />
        <SignInButton provider={SignInProvider.sso} link="/sso-login" />
        <TextSurroundedByLines>or</TextSurroundedByLines>
        <EmailSignIn
          submitUrl="/signin/email"
          submitMessage="Check your inbox for a sign in link"
          buttonText="Continue with email"
        />
      </Card>
    </Box>
  );
}

const SignupCalloutWrapper = styled.div`
  position: absolute;
  top: 42px;
  right: 48px;
  font-size: 12px;
  text-align: right;
`;

const LinkWrapper = styled.div`
  > * {
    font-size: 16px;
    color: ${Colors.Blue500};
    &:hover {
      color: ${Colors.Blue700};
    }
  }
`;

export {Login};
