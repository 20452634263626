import {Box, Colors} from '@dagster-io/ui-components';
import React from 'react';

import {Card} from './card';
import {Button} from '../components/button';

export function SelectEmail({path}: {path: string}) {
  const params = React.useMemo(() => new URLSearchParams(window.location.search), []);
  const emails: string[] = React.useMemo(() => {
    try {
      return JSON.parse(window.atob(params.get('emails')!));
    } catch {
      return [];
    }
  }, [params]);
  return (
    <Card title="Select an email" flex={{direction: 'column', gap: 10}}>
      <Box flex={{direction: 'column', gap: 6}}>
        {emails.map((email) => {
          if (email.endsWith('@users.noreply.github.com')) {
            return null;
          }
          return (
            <Button
              key={email}
              as="a"
              href={`${path}?token=${params.get('token')}&email=${email}`}
              style={{color: Colors.Dark}}
            >
              {email}
            </Button>
          );
        })}
      </Box>
    </Card>
  );
}
