import * as React from 'react';
import {useLocation} from 'react-router-dom';

import {useAnalytics} from '../contexts/analytics';
import {PageViewContext} from '../contexts/pageView';

interface Props {
  children: React.ReactNode;
  path: string;
}

/**
 * A wrapper component for all "page-level" components. The `path` prop provided here
 * should be the same as the `to` string provided on its <Route>, and will be used for
 * analytics tracking.
 */
export const Page = ({children, path}: Props) => {
  const analytics = useAnalytics();
  const {pathname: specificPath} = useLocation();

  // Register a page view at this route.
  React.useEffect(() => {
    analytics?.page({path, specificPath});
  }, [analytics, path, specificPath]);

  // Provide page context for subsequent analytics event tracking.
  const value = React.useMemo(() => ({path, specificPath}), [path, specificPath]);
  return <PageViewContext.Provider value={value}>{children}</PageViewContext.Provider>;
};
