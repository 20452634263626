import {Colors} from '@dagster-io/ui-components';
import React from 'react';
import styled from 'styled-components';

type TitleProps = {
  subtext?: React.ReactNode;
} & Parameters<typeof TitleElement>[0];

export const Title = ({children, subtext, style, ...rest}: TitleProps) => {
  return (
    <>
      <TitleElement style={{marginBottom: subtext ? 0 : undefined, ...style}} {...rest}>
        {children}
      </TitleElement>
      {subtext}
    </>
  );
};
// 0 Top margin because the Title should be the first thing in a <Card>
const TitleElement = styled.h1`
  font-family: 'Neue Montreal', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  margin: 0px auto 18px auto;
  color: ${Colors.ForestGreen};
`;

export const Subtext = styled.div`
  color: #6b6762;
  font-size: 16px;
  line-height: 18px;
`;

export const Subtext2 = styled.div`
  color: #6b6762;
  font-size: 14px;
  line-height: 17px;
`;

export const Emphasized = styled.div`
  color: ${Colors.Dark};
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;
