import * as React from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

import {AnalyticsProvider} from './contexts/analytics';
import {extractCloudPortalSettings} from './extractCloudPortalSettings';

import './index.css';
import './fonts.css';

interface Props {
  children: React.ReactNode;
}

export const AppProvider = ({children}: Props) => {
  const {recaptchaSiteKey, segmentWriteKey, segmentCDNProxy} = extractCloudPortalSettings();
  return (
    <AnalyticsProvider segmentWriteKey={segmentWriteKey} segmentCDNProxy={segmentCDNProxy}>
      <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey} useEnterprise>
        {children}
      </GoogleReCaptchaProvider>
    </AnalyticsProvider>
  );
};
