import {Box, Colors} from '@dagster-io/ui-components';
import React from 'react';
import {BrowserRouter, Link, Route, Routes} from 'react-router-dom';
import styled from 'styled-components';
import WebFont from 'webfontloader';

import './App.css';
import dagsterlabs from './assets/dagsterlabs.svg';
import {Logo} from './components/logo';
import {Page} from './components/page';
import {SetReferrerCookie} from './hooks/useSetReferrerCookie';
import {SSOLogin} from './pages/SSOLogin';
import {UserProfile} from './pages/UserProfile';
import {Login} from './pages/login';
import {SelectOrganization} from './pages/selectOrganization';
import {SigninEmailLanding} from './pages/signinEmailLanding';
import {SigninSelectEmail} from './pages/signinSelectEmail';
import {Signup} from './pages/signup';
import {SignupOrganization} from './pages/signupOrganization';
import {SignupSelectEmail} from './pages/signupSelectEmail';

WebFont.load({
  google: {
    families: ['Inter:400'],
  },
});

function App() {
  return (
    <div style={{minHeight: '100%'}}>
      <Background />
      <ContainerOuter>
        <Container flex={{direction: 'column'}}>
          <Logo />
          <Content>
            <BrowserRouter>
              <SetReferrerCookie />
              <Routes>
                <Route
                  path="/"
                  element={
                    <Page path="/">
                      <Login />
                    </Page>
                  }
                />
                <Route
                  path="/login"
                  element={
                    <Page path="/login">
                      <Login />
                    </Page>
                  }
                />
                <Route
                  path="/sso-login"
                  element={
                    <Page path="/sso-login">
                      <SSOLogin />
                    </Page>
                  }
                />
                <Route
                  path="/auth/select-organization"
                  element={
                    <Page path="/auth/select-organization">
                      <SelectOrganization />
                    </Page>
                  }
                />
                <Route
                  path="/signup"
                  element={
                    <Page path="/signup">
                      <Signup />
                    </Page>
                  }
                />
                <Route
                  path="/signup/organization"
                  element={
                    <Page path="/signup/organization">
                      <SignupOrganization />
                    </Page>
                  }
                />
                <Route
                  path="/signup/select-email"
                  element={
                    <Page path="/signup/select-email">
                      <SignupSelectEmail />
                    </Page>
                  }
                />
                <Route
                  path="/signin/select-email"
                  element={
                    <Page path="/signin/select-email">
                      <SigninSelectEmail />
                    </Page>
                  }
                />
                <Route
                  path="/signin/email-confirm"
                  element={
                    <Page path="/signin/email-confirm">
                      <SigninEmailLanding />
                    </Page>
                  }
                />
                <Route
                  path="/user-profile"
                  element={
                    <Page path="/user-profile">
                      <UserProfile />
                    </Page>
                  }
                />
                <Route
                  path="*"
                  element={
                    <Page path="*">
                      <NoMatch />
                    </Page>
                  }
                />
              </Routes>
            </BrowserRouter>
          </Content>
          <Footer />
        </Container>
      </ContainerOuter>
    </div>
  );
}

const ContainerOuter = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: scroll;

  a {
    color: ${Colors.Link};
  }
`;

const Container = styled(Box)`
  width: 392px;
  margin: 0 auto;
  min-height: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
`;

function Footer() {
  return <ImageFooter src={dagsterlabs} width={160} />;
}

const ImageFooter = styled.img`
  text-align: center;
  margin: 0 auto;
  padding: 34px 0;
  display: block;
  filter: grayscale(1);
  opacity: 60%;
`;

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export {App};

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background:
    radial-gradient(circle at top left, rgba(220, 218, 247, 1), rgba(237, 236, 252, 0.29)),
    radial-gradient(circle at top right, rgba(167, 255, 191, 0.44), rgba(22, 214, 74, 0))
      rgba(255, 255, 255, 1);
`;
